<template>
	<div @click="cancel">
		<div class="report_head">
			<div class="report_item">
				<p class="report_item_title">累计支出酒店费用(元)</p>
				<p class="report_item_num">{{ViewCount.total_price}}</p>
			</div>
			<div class="report_item">
				<p class="report_item_title">本年度支出酒店费用(元)</p>
				<p class="report_item_num">{{ViewCount.year_price}}</p>
				<p class="report_item_rate">环比 <span
						:class="ViewCount.year_rate>0?'item_rate_red':'item_rate_green'">{{ViewCount.year_rate}}%
						{{ViewCount.year_rate>0?'↑':'↓'}}</span></p>
			</div>
			<div class="report_item">
				<p class="report_item_title">本季度支出酒店费用(元)</p>
				<p class="report_item_num">{{ViewCount.season_price}}</p>
				<p class="report_item_rate">环比 <span
						:class="ViewCount.season_rate>0?'item_rate_red':'item_rate_green'">{{ViewCount.season_rate}}%
						{{ViewCount.season_rate>0?'↑':'↓'}}</span></p>
			</div>
			<div class="report_item">
				<p class="report_item_title">本月支出酒店费用(元)</p>
				<p class="report_item_num">{{ViewCount.month_price}}</p>
				<p class="report_item_rate">环比 <span
						:class="ViewCount.month_rate>0?'item_rate_red':'item_rate_green'">{{ViewCount.month_rate}}%
						{{ViewCount.month_rate>0?'↑':'↓'}}</span></p>
			</div>
		</div>
		<div class="report_box1 ">
			<div class="report_order_money ">
				<div>
					<div class="report_title">
						<p>酒店订单金额</p>
						<p>
							<span v-for="(item,index) in tabs" :class="index1==index?'act_item1':''"
								@click="change_tab(1,index)">{{item}}</span>
						</p>
						<p>
							<a-range-picker @change="onChange1($event,1)">
								<a-icon slot="suffixIcon" type="calendar" />
							</a-range-picker>
						<p class="dot_info"><span @click.stop="open_down(1)">···</span> <span class="down_info"
								v-if="down1">
								<a :href="this.$config.host+'/manage/crm.trip_count/exportHotelOrder?end_time='+order_time2+'&start_time='+order_time1+'&type='+(order_time1?'4':index1+1)+'&token='+token"  target="_blank">下载报表</a>
								</span></p>
					</div>
				</div>
				<div>
					<div id="order_money1" style="height:300px; width:100%"></div>
				</div>
			</div>
			<div class="report_hot_city">
				<div class="report_title">
					<p>TOP10酒店预定部门</p>
					<p class="dot_info"><span @click.stop="open_down(2)">···</span> <span class="down_info down_info2"
							v-if="down2"><a :href="this.$config.host+'/manage/crm.trip_count/exportHotelDepart?token='+token"  target="_blank">下载报表</a></span></p>
				</div>
				<div class="hot_dap">
					<p class="dap_item" v-for="(item,index) in departCount" :key='index'>
						<span
							:class="index+1<=3?'span1 top_5':'span1'">{{index+1}}</span><span>{{item.xaxis}}</span><span>{{item.num}}</span>
					</p>
				</div>
			</div>
		</div>
		<div class="report_box1 report_box2">
			<div class="report_order_money">
				<div>
					<div class="report_title">
						<p>出差人数</p>
						<p>
							<span v-for="(item,index) in tabs" :class="index2==index?'act_item1':''"
								@click.stop="change_tab(2,index)">{{item}}</span>
						</p>
						<p>
							<a-range-picker @change="onChange1($event,2)">
								<a-icon slot="suffixIcon" type="calendar" />
							</a-range-picker>
						<p class="dot_info"><span @click.stop="open_down(3)">···</span> <span
								class="down_info down_info2" v-if="down3">
								<a :href="this.$config.host+'/manage/crm.trip_count/exportPersonsNum?end_time='+peroson_time2+'&start_time='+peroson_time1+'&type='+(peroson_time1?'4':index2+1)+'&token='+this.token"  target="_blank">下载报表</a>
								</span></p>
					</div>
				</div>
				<div>
					<div id="bt_number"></div>
				</div>
			</div>
			<div class="report_order_money">
				<div>
					<div class="report_title">
						<p>酒店预订率</p>
						<p>
							<span v-for="(item,index) in tabs" :class="index3==index?'act_item1':''"
								@click="change_tab(3,index)">{{item}}</span>
						</p>
						<p>
							<a-range-picker @change="onChange1($event,3)">
								<a-icon slot="suffixIcon" type="calendar" />
							</a-range-picker>
						<p class="dot_info"><span @click.stop="open_down(7)">···</span> <span
								class="down_info down_info2" v-if="down7">
								<a :href="this.$config.host+'/manage/crm.trip_count/exportHotelOrdain?end_time='+ordain_time2+'&start_time='+ordain_time1+'&type='+(ordain_time1?'4':index5+1)+'&token='+this.token"  target="_blank">下载报表</a>
								</span></p>
					</div>
				</div>
				<div>
					<div id="hotel_rota"></div>
				</div>
			</div>
			<div class="report_hot_city">
				<div class="report_title">
					<p>TOP10出差地</p>
					<p class="dot_info"><span @click.stop="open_down(4)">···</span> <span class="down_info down_info2"
							v-if="down4">
							<a :href="'/manage/crm.trip_count/exportHotelDepart' | export_form"  target="_blank">下载报表</a>
							</span></p>
				</div>
				<div class="hot_dap">
					<p class="dap_item" v-for="(item,index) in placeCount" :key='index'>
						<span
							:class="index+1<=3?'span1 top_5':'span1'">{{index+1}}</span><span>{{item.xaxis}}</span><span>{{item.num}}</span>
					</p>
				</div>
			</div>
		</div>

		<div class="report_box1 report_box2">
			<div class="report_order_money">
				<div>
					<div class="report_title">
						<p>差旅申请数</p>
						<p>
							<span v-for="(item,index) in tabs" :class="index4==index?'act_item1':''"
								@click="change_tab(4,index)">{{item}}</span>
						</p>
						<p>
							<a-range-picker @change="onChange1($event,4)">
								<a-icon slot="suffixIcon" type="calendar" />
							</a-range-picker>
							
						<p class="dot_info"><span @click.stop="open_down(5)">···</span> <span class="down_info down_info2"
								v-if="down5">
								<a :href="this.$config.host+'/manage/crm.trip_count/exportTripApproval?end_time='+trip_time2+'&start_time='+trip_time1+'&type='+(trip_time1?'4':index4+1)+'&token='+this.token"  target="_blank">下载报表</a>
								</span></p>
					</div>
				</div>
				<div>
					<div id="approval_numbel"></div>
				</div>
			</div>
			<div class="report_order_money">
				<div>
					<div class="report_title">
						<p>差旅审批耗时</p>
						<p>
							<span v-for="(item,index) in tabs" :class="index5==index?'act_item1':''"
								@click="change_tab(5,index)">{{item}}</span>
						</p>
						<p>
							<a-range-picker @change="onChange1($event,5)">
								<a-icon slot="suffixIcon" type="calendar" />
							</a-range-picker>
						<p class="dot_info"><span @click.stop="open_down(6)">···</span> <span class="down_info down_info2"
								v-if="down6">
								<a :href="this.$config.host+'/manage/crm.trip_count/exportApprovalTime?end_time='+app_time2+'&start_time='+app_time1+'&type='+(app_time1?'4':index5+1)+'&token='+this.token"  target="_blank">下载报表</a>
								</span></p>
					</div>
				</div>
				<div>
					<div id="approval_time"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		tripApprovalCount,
		personsNumCount,
		hotelOrderCount,
		overViewCount,
		tripPlaceCount,
		hotelDepartCount,
		hotelOrdainCount,
		approvalTimeCount
	} from "@/api/evection.js";
	import {
		Chart
	} from '@antv/g2';
	import echarts from 'echarts';

	export default {
		name: "Index",
		components: {},
		data() {
			return {
				peroson_time2:'',
				peroson_time1:'',
				ordain_time1:'',
				ordain_time2:'',
				trip_time2:'',
				trip_time1:'',
				app_time2:'',
				app_time1:'',
				order_time2:'',
				order_time1:'',
				bt_number1:null,
				hotel_rota1:null,
				approval_time1: null,
				order_money1: null,
				approval_numbel1:null,
				tabs: ['昨日', '近7日', '近30日'],
				index1: 0,
				index2: 0,
				index3: 0,
				index4: 0,
				index5: 0,
				down1: false,
				down2: false,
				down3: false,
				down4:false,
				down5: false,
				down6: false,
				down7: false,
				ViewCount: {},
				placeCount: [],
				departCount: [],
				url:'',
				token:''
			};
		},
		async created() {
			this.trip_ApprovalCount()
			this.persons_NumCount()
			this.over_ViewCount()
			this.trip_PlaceCount()
			this.hotel_DepartCount();
			this.hotel_OrdainCount()
			this.get_time()
			this.hotel_OrderCount()
			this.token = this.$method.get_local('token');
		},
		mounted() {
			const e = document.createEvent('Event')
			e.initEvent('resize', true, true)
			window.dispatchEvent(e)
		},
		methods: {
			hotel_DepartCount() {
				hotelDepartCount().then(res => {
					this.departCount = res.data.list
				})
			},
			trip_PlaceCount() {
				tripPlaceCount().then(res => {
					this.placeCount = res.data.list
				})
			},
			trip_ApprovalCount(data1,data2) {
				tripApprovalCount({
					data:{
						start_time:data1?data1:'',
						end_time:data2?data2:'',
						type: data1?'4':this.index3 + 1
					}
				}).then(res => {
					let data = res.data.list
					if(!this.approval_numbel1){
						this.created_chart4()
					}
					this.approval_numbel1.data(res.data.list)
					this.approval_numbel1.render();
				})
			},
			persons_NumCount(data1,data2) {
				personsNumCount({
					data: {
						start_time:data1?data1:'',
						end_time:data2?data2:'',
						type: data1?'4':this.index2 + 1
					}
				}).then(res => {
					if(!this.bt_number1){
						this.created_chart3()
					}
					this.bt_number1.data(res.data.list)
					this.bt_number1.render();
				})
			},
			over_ViewCount() {
				overViewCount().then(res => {
					this.ViewCount = res.data.list
				})
			},
			cancel() {
				for (let i = 0; i < 7; i++) {
					this['down' + (i + 1)] = false
				}

			},
			open_down(type) {
				this['down' + type] = !this['down' + type]
			},
			change_tab(type, index) {
				this['index' + String(type)] = index;
				if (type == 1) {
					this.order_time1='';
					this.order_time2=''
					this.hotel_OrderCount()
				}
				if (type == 5) {
					this.app_time1='';
					this.app_time2=''
					this.get_time()
					
				}
				if(type==2){
					this.peroson_time2=''
					this.peroson_time1=''
					this.persons_NumCount()
					
				}
				if(type==3){
					this.ordain_time1 = ''
					this.ordain_time2= ''
					this.hotel_OrdainCount()
				}
				if(type==4){
					this.trip_time2=''
					this.trip_time1=''
					this.trip_ApprovalCount()
				}
			},
			created_chart() {
				const chart1 = new Chart({
					container: 'order_money1',
					autoFit: true,
					height: 300,
					theme: 'default',
					padding: [40, 40, 50, 100],
				});
				this.order_money1 = chart1
				chart1.scale('price', {
					alias: '订单金额/元',
				});
				chart1.tooltip({
					showCrosshairs: true,
					shared: true,
				});
				let data = []
				chart1.data(data);
				chart1.interval().position('xaxis*price');
				chart1.render();
			},
			created_chart2() {
				const chart2 = new Chart({
					container: 'approval_time',
					autoFit: true,
					height: 300,
					theme: 'default',
					padding: [40, 100, 50, 100],
				});
				this.approval_time1 = chart2
				chart2.scale('num', {
					alias: '审批耗时/分',
				});

				chart2.tooltip({
					showCrosshairs: true,
					shared: true,
				});
				let data = []
				chart2.data(data);
				chart2.interval().position('xaxis*num');
				
				chart2.render();
			},
			created_chart3() {
				var chart3 = new Chart({
					container: document.getElementById('bt_number'),
					forceFit: true,
					autoFit: true,
					height: 300,
					padding: [40, 40, 50, 70],
					theme: 'default'
				});
				this.bt_number1 = chart3
				chart3.data([]);
				chart3.scale('num', {
					min: 0,
					alias: '出差人数',
				});
				chart3.scale('xaxis', {
					range: [0, 1]
				});
				chart3.tooltip({
					showCrosshairs: true,
					crosshairs: {
						type: 'line'
					}
				});
				
				chart3.line().position('xaxis*num').shape('smooth');
				chart3.point().position('xaxis*num').size(4).shape('circle').style({
					stroke: '#fff',
					lineWidth: 1
				});
				chart3.area()
				.position('xaxis*num')
				.shape('smooth');
				chart3.render();
			},
			
			created_chart5() {
				var chart5 = new Chart({
					container: document.getElementById('hotel_rota'),
					forceFit: true,
					autoFit: true,
					height: 300,
					padding: [40, 40, 50, 100],
					theme: 'default'
				});
				this.hotel_rota1 = chart5
				chart5.data([]);
				chart5.scale('num', {
					min: 0,
					alias: '酒店预订率/%',
				});
				chart5.scale('xaxis', {
					range: [0, 1]
				});
				chart5.tooltip({
					crosshairs: {
						type: 'line'
					}
				});
			
				chart5.line().position('xaxis*num').shape('smooth');
				chart5.point().position('xaxis*num').size(4).shape('circle').style({
					stroke: '#fff',
					lineWidth: 1
				});
				 chart5.area().shape('smooth').position('xaxis*num');
				 chart5.render();
			},
			created_chart4() {
				var chart4 = new Chart({
					container: document.getElementById('approval_numbel'),
					forceFit: true,
					autoFit: true,
					height: 300,
					padding: [40, 40, 50, 100],
					theme: 'default'
				});
				this.approval_numbel1 = chart4
				chart4.data([]);
				chart4.scale('num', {
					min: 0,
					alias: '申请数量/人',
				});
				chart4.scale('xaxis', {
					range: [0, 1]
				});
				chart4.tooltip({
						showCrosshairs: true,
					crosshairs: {
						type: 'line'
					}
				});
			
				chart4.line().position('xaxis*num').shape('smooth');
				chart4.point().position('xaxis*num').size(4).shape('circle').style({
					stroke: '#fff',
					lineWidth: 1
				});
				 chart4.area().shape('smooth').position('xaxis*num');
				chart4.render();
			},
			hotel_OrderCount(data1,data2) {
				hotelOrderCount({
					data: {
						start_time:data1?data1:'',
						end_time:data2?data2:'',
						type: data1?'4':this.index1 + 1
					}
				}).then(res => {
					if (!this.order_money1) {
						this.created_chart()
					}
					this.order_money1.data(res.data.list)
					this.order_money1.render();
				})

			},
			get_time(data1,data2) {
				approvalTimeCount({
					data: {
						start_time:data1?data1:'',
						end_time:data2?data2:'',
						type: data1?'4':this.index5 + 1
					}
				}).then(res => {
					if (!this.approval_time1) {
						this.created_chart2()
					}
					this.approval_time1.data(res.data.list)
					this.approval_time1.render();
				})

			},
			hotel_OrdainCount(data1,data2) {
				hotelOrdainCount({
					data: {
						start_time:data1?data1:'',
						end_time:data2?data2:'',
						type: data1?'4':this.index3 + 1
					}
				}).then(res => {
					if(!this.hotel_rota1) {
						this.created_chart5()
					}
					this.hotel_rota1.data(res.data.list)
					this.hotel_rota1.render();
				})


			},

			show() {
				this.drawer_visible = true;
			},
			onChange1(e,type){
				
				
				let data1 = this.$method.get_date(e[0]);
				let data2 = this.$method.get_date(e[1]);
				if(type==1){
					this.hotel_OrderCount(data1,data2)
					this.order_time1=data1;
					this.order_time2=data2
				}
				if(type==2){
					this.peroson_time2=data2
					this.peroson_time1=data1
					this.persons_NumCount(data1,data2)
				}
				if(type==5){
					this.app_time1=data1;
					this.app_time2=data2
					this.get_time(data1,data2)
				}
				if(type==3){
					this.hotel_OrdainCount(data1,data2)
					this.ordain_time1 = data1
					this.ordain_time2= data2
				}
				if(type==4){
					this.trip_time2=data2
					this.trip_time1=data1
					this.trip_ApprovalCount(data1,data2)
				}
				
			},
		
			onChange() {},
			drawer_visible_close() {
				this.drawer_visible = false;
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.report_head {
		display: flex;
		padding: 15px;

		// margin: 15px;
		.report_item {
			flex: 1;
			height: 128px;
			opacity: 1;
			background: #ffffff;
			border-radius: 4px;
			box-shadow: 0px 0px 8px 0px rgba(112, 120, 128, 0.10);
			margin-left: 22px;
			padding: 18px 36px;
		}

		.report_item:nth-child(1) {
			margin-left: 0px;
		}

		.report_item_title {
			font-size: 13px;
			font-family: PingFang SC, PingFang SC-Regular;
			font-weight: 400;
			color: #8c8c8c;
			line-height: 20px;
		}

		.report_item_num {
			opacity: 1;
			font-size: 25px;
			font-family: DIN, DIN-Medium;
			font-weight: 500;
			text-align: left;
			color: #262626;
			line-height: 23px;
			margin-top: 14px;
		}

		.report_item_rate {
			height: 20px;
			opacity: 1;
			font-size: 14px;
			font-family: PingFangSC-Regular;
			text-align: left;
			color: #595959;
			line-height: 22px;
			margin-top: 14px;
		}

		.item_rate_red {
			font-size: 14px;
			font-family: PingFangSC-Regular;
			text-align: left;
			color: #ff4d4f;
			line-height: 22px;
		}

		.item_rate_green {
			font-size: 13px;
			font-family: PingFangSC-Regular;
			text-align: left;
			color: #52c41a;
			line-height: 20px;
		}
	}

	.report_box1 {
		padding: 15px;
		display: flex;
		padding-top: 0px;

		.report_order_money {
			flex: 1;
			height: 400px;
			background: #fff;

			.report_title {
				width: 100%;
				display: flex;

				p:nth-child(1) {
					flex: 6;
					font-size: 16px;
					font-family: PingFangSC-Medium;
					text-align: left;
					margin-left: 24px;
					color: rgba(0, 0, 0, 0.85);
				}

				p:nth-child(2) {
					flex: 1;
					margin-right: 10px;
					text-align: right;
					font-size: 18px;
					display: flex;
					align-items: center;

					span {
						width: 60px;
						height: 32px;
						background: #ffffff;
						color: D9D9D9;
						// border-radius: 2px 0px 0px 2px;
						font-size: 14px;
						font-weight: 400;
						text-align: left;

						line-height: 32px;
						text-align: center;
						border: 1px solid #D9D9D9;
					}

					.act_item1 {
						color: #1890ff;
						border-color: #1890ff;
					}
				}

				p:nth-child(3) {
					flex: 2.2;
					margin-right: 10px;
					font-size: 18px;
					margin-top: -2px;
				}

				p:nth-child(4) {
					flex: 0.3;
					margin-right: 24px;
					text-align: right;
					font-size: 18px;
				}
			}
		}

		.report_hot_city {
			width: 334px;
			height: 400px;
			opacity: 1;
			background: #ffffff;
			border-radius: 2px;
			margin-left: 20px;
		}
	}

	.report_title {
		width: 334px;
		height: 52px;
		opacity: 1;
		border-radius: 2px;
		display: flex;
		line-height: 52px;
		border-bottom: 1px solid #E8E8E8;

		p:nth-child(1) {
			flex: 2;
			font-size: 16px;
			font-family: PingFangSC-Medium;
			text-align: left;
			margin-left: 24px;
			color: rgba(0, 0, 0, 0.85);
		}

		p:nth-child(2) {
			flex: 1;
			margin-right: 24px;
			text-align: right;
			font-size: 18px;
		}
	}

	.hot_dap {
		margin-left: 24px;

		.dap_item {
			display: flex;
			align-items: center;
			margin-top: 12px;

			.span1 {
				width: 20px;
				height: 20px;
				opacity: 1;
				border-radius: 50%;
				text-align: center;
				line-height: 20px;
				margin-right: 24px;
				background: #F0F2F5;
				color: #000000;
			}

			.top_5 {
				background: #314659;
				color: #fff;
			}

			span:nth-child(2) {
				flex: 7;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Regular;
				text-align: left;
				color: rgba(0, 0, 0, 0.85);
				line-height: 22px;
			}

			span:nth-child(3) {
				flex: 2;
				flex: 3;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Regular;
				text-align: left;
				color: rgba(0, 0, 0, 0.85);
				line-height: 22px;
			}
		}

		.dap_item:nth-child(1) {
			margin-top: 24px;
		}
	}

	.report_box2 {
		.report_order_money {
			flex: 1;
			height: 400px;
			background: #fff;

			.report_title {
				width: 100%;
				display: flex;

				p:nth-child(1) {
					flex: 1.5;
					font-size: 16px;
					font-family: PingFangSC-Medium;
					text-align: left;
					margin-left: 24px;
					color: rgba(0, 0, 0, 0.85);
				}
			}
		}

		.report_order_money:nth-child(2) {
			margin-left: 20px;
		}
	}

	#report_order_money {
		width: 500px;
		height: 300px;
	}

	.dot_info {
		position: relative;

		.down_info {
			border: 1px solid #0079FF;
			position: absolute;
			color: #0079FF;
			border-radius: 2px;
			width: 80px;
			height: 30px;
			line-height: 30px;
			opacity: 1;
			font-size: 14px;
			text-align: center;
			top: 40px;
			left: -20px;
			background: #fff;
		}

		.down_info2 {
			left: 10px;
		}
	}
</style>
